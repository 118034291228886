import React from 'react';
import logo from './imgs/ampersandbot.png';
import './styles/App.css';

function App() {
    const lang = navigator.language;
    let langData: {[value: string]: string};
    switch (lang) {
        case 'pl':
            langData = {
                sub: 'Twój bot na Discord',
                dashText: 'Panel oraz wersja 5 już wkrótce!',
                title: 'AmpersandBot - Rozwój zawieszony',
                description: `
                    Z przykrością informuję, iż prace nad projektem AmpersandBot zostały zawieszone.<br />
                    Dalszych zainteresowanych pragnę zachęcić do bota <span style="color: #86c232; font-weight: 600;">StartIT</span>.
                `
            }
            break;
        default:
            langData = {
                sub: 'Your bot on Discord',
                dashText: 'Dashboard and version 5 coming soon!',
                title: 'AmpersandBot - Development Halted',
                description: `
                    I regret to inform you that work on the AmpersandBot project has been halted.<br />
                    I would like to encourage further interested parties to the <span style="color: #86c232; font-weight: 600;">StartIT</span> bot.
                `
            }
            break;
    }
    return (
        <div className="App">
            <header className="App-header">
                <div className="App-logo-div">
                    <img src={logo} className="App-logo" alt="logo"/>
                    <h3>AmpersandBot</h3>
                </div>
                {/*<p>*/}
                {/*    {langData.sub}<br/>*/}
                {/*    <b>*/}
                {/*    <small>*/}
                {/*            {langData.dashText}*/}
                {/*        </small>*/}
                {/*    </b>*/}
                {/*</p>*/}
                {/*<div className="App-buttons">*/}
                {/*    <a href="https://discord.gg/ggvm5GKbev" target="_blank" rel="noopener noreferrer" className="button">*/}
                {/*        <FontAwesomeIcon icon={faDiscord} />*/}
                {/*        Discord*/}
                {/*    </a>*/}
                {/*</div>*/}
                <div className="content">
                    <h3>{langData.title}</h3>
                    <p dangerouslySetInnerHTML={{__html: langData.description}} />
                    <a href="https://startit.bot" target="_blank" rel="noopener noreferrer" className="button">
                        <svg style={{display: "inline-block"}} xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 38 38" fill="none">
                            <path
                                d="M30.1636 6.82445C27.7979 5.11173 25.3482 3.52105 22.8861 1.95107C21.889 1.31451 20.8576 0.670812 19.8164 0L19.8164 8.08984C19.8076 9.02898 20.1634 9.7589 20.9883 10.2506C21.3651 10.4747 21.7389 10.7002 22.1009 10.9428C23.9987 12.2152 25.502 13.8087 26.3019 15.9432C27.1628 18.2383 27.1775 20.5832 26.555 22.9211C25.6293 26.3958 23.5594 28.9762 20.092 30.3978C19.897 30.482 19.8906 30.5717 19.8906 30.7266C19.8906 33.0287 19.897 35.6974 19.897 38C19.897 38 22.2275 37.6426 22.8949 37.4285C26.1768 36.3902 28.9737 34.6396 31.1438 32.0135C33.8554 28.7336 35.2402 24.9492 35.5588 20.7831C35.7043 18.8855 35.6097 16.9778 35.277 15.1026C34.6751 11.7043 33.0533 8.91538 30.1636 6.82445Z"
                                fill="url(#paint0_linear_1056_12225)"></path>
                            <path
                                d="M19.7422 23.75C19.0085 23.6265 18.3586 23.5395 17.6771 23.3886C13.7061 22.4923 10.4674 20.4107 7.80511 17.4293C5.31441 14.6416 3.69899 11.4139 2.87949 7.80087C2.41432 5.75458 2.26963 3.65066 2.45042 1.56159C2.48761 1.12645 2.57128 0.694825 2.60989 0.259689C2.62992 0.0301902 2.74362 -0.000690354 2.94456 1.14776e-05C4.78452 0.00562613 6.62519 0.00352063 8.46515 0.00352063H12.0657V0.452693C12.0657 1.65072 12.0328 2.84945 12.0793 4.04607C12.1687 6.34317 12.5799 8.57078 13.7784 10.5949C14.6533 12.0863 15.9189 13.3207 17.444 14.17C18.0161 14.4907 18.5581 14.8508 19.1052 15.2073C19.5421 15.488 19.7465 15.8409 19.7422 16.4023C19.7257 18.7605 19.7272 21.095 19.7422 23.4531V23.75Z"
                                fill="url(#paint1_linear_1056_12225)"></path>
                            <defs>
                                <linearGradient id="paint0_linear_1056_12225" x1="33.7292" y1="1.75124" x2="15.0287"
                                                y2="9.39925" gradientUnits="userSpaceOnUse">
                                    <stop stop-color="#C4F381"></stop>
                                    <stop offset="1" stop-color="#86C232"></stop>
                                </linearGradient>
                                <linearGradient id="paint1_linear_1056_12225" x1="17.6596" y1="1.09452" x2="1.84946"
                                                y2="12.4599" gradientUnits="userSpaceOnUse">
                                    <stop stop-color="#C4F381"></stop>
                                    <stop offset="1" stop-color="#86C232"></stop>
                                </linearGradient>
                            </defs>
                        </svg>
                        StartIT
                    </a>
                </div>
            </header>
        </div>
    );
}

export default App;
